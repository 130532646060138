import { Outlet } from 'react-router-dom'

import { Aside } from '@/components'

import { CallProvider } from '@/providers'

import { useAppSelector } from '@/store'

const MainLayout = () => {
	const asideIsOpen = useAppSelector((state) => state.app.isAsideActive)

	return (
		<>
			<main className={`main ${!asideIsOpen === true ? '_aside-close' : ''}`}>
				<Aside />
				<div className="main__right">
					<CallProvider>
						<Outlet />
					</CallProvider>
				</div>
			</main>
		</>
	)
}

export default MainLayout
