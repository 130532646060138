import { DatePicker, Table, TableProps } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getPaymentsList, listPayments } from 'store/appeals'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const { RangePicker } = DatePicker
type OnChange = NonNullable<TableProps<listPayments>['onChange']>
type Filters = Parameters<OnChange>[1]

interface FilterParams {
	id: string
	StartedAt?: string
	EndedAt?: string
	name?: string
	moneySum: number
	paymentMethod?: string
	personalAccount?: number
}

const Payments = () => {
	const data = useAppSelector((state) => state.appeals.listPayments)
	const [filteredInfo, setFilteredInfo] = useState<Filters>({})
	const dispatch = useAppDispatch()

	const [dateFilter, setDateFilter] = useState<{
		StartedAt: string
		EndedAt: string
	} | null>(null)

	const fetchData = async () => {
		dispatch(getPaymentsList({}))
	}

	const columns = [
		{
			title: 'Лицевой счет',
			dataIndex: 'appeal',
			key: 'appeal',
			render: (e: any) => <span>{e?.abonent?.personalAcc}</span>,
		},
		{
			title: 'Имя терминала',
			dataIndex: 'appeal',
			key: 'appeal',
			render: (e: any) => <span>{e?.terminal?.name}</span>,
		},
		{
			title: 'Время',
			render: (e: any) => (
				<div>
					{moment(e.dateTimeCreation).format('YYYY-MM-DD, HH:mm:ss')}
					<br></br>
					{moment(e.dateTimeLastEditing).format('YYYY-MM-DD, HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'Сумма',
			dataIndex: 'moneySum',
			key: 'moneySum',
		},
		{
			title: 'Способ оплаты',
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
		},

		{
			title: 'В реестре',
			dataIndex: 'isSentToBilling',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
		{
			title: 'Ссылка на чек',
			dataIndex: 'receipt',
			key: 'receipt',
		},
		{
			title: 'Результат платежа',
			dataIndex: 'paymentTerminalIsSuccess',
			render: (e: any) => <span>{e ? 'Успешно' : 'Ошибка'}</span>,
		},
		{
			title: 'Распечатан чек',
			dataIndex: 'receiptIsPrinted',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
		{
			title: 'Отпарвлен в OFD',
			dataIndex: 'receiptIsSentToOFD',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
	]

	const onDateChange = (date: any, dateString: any) => {
		setDateFilter({
			StartedAt: dateString[0],
			EndedAt: dateString[1],
		})
		dispatch(
			getPaymentsList({ StartedAt: dateString[0], EndedAt: dateString[1] })
		)
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className="payments">
			<div className="payments__title">Список платежей</div>
			<div className="jornal__filter">
				<div className="jornal__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>
			<Table dataSource={data} columns={columns} />
		</div>
	)
}

export default Payments
