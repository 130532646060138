import { LoginOutlined } from '@ant-design/icons'
import { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
	getStatusList,
	operatorItem,
	terminalItem,
	useAppDispatch,
} from '@/store'

import { TERMINALS_PATH } from '@/utils'

import TerminalOperators from './TerminalOperators'

interface TerminalListItemProps {
	item: terminalItem
	statuses: {
		status: string
		devicesStatus: {
			printerStatus: string
			scanerStatus: string
			kkmStatus: string
			acquiringStatus: string
		}
	} | null
	operators?: operatorItem[]
}

const statusName: {
	[key: string]: {
		status: string
		class: string
	}
} = {
	Online: {
		status: 'В сети',
		class: '_active',
	},
	Offline: {
		status: 'Не в сети',
		class: '_offline',
	},
	Disabled: {
		status: 'Отключен',
		class: '_offline',
	},
	None: {
		status: 'Ошибка',
		class: 'None',
	},
}

export function TerminalListItem({
	item,
	statuses,
	operators,
}: TerminalListItemProps) {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(getStatusList())
	}, [dispatch])

	// Мемоизация статусов устройств
	const deviceStatus = useMemo(
		() => ({
			printerStatus: statuses?.devicesStatus?.printerStatus,
			scanerStatus: statuses?.devicesStatus?.scanerStatus,
			kkmStatus: statuses?.devicesStatus?.kkmStatus,
			acquiringStatus: statuses?.devicesStatus?.acquiringStatus,
		}),
		[statuses]
	)

	return (
		<li className={`terminals__item ${statusName[item.astipStatus].class}`}>
			<div className="terminals__top">
				<div className="terminals__left">
					<div className="terminals__name">
						<Link to={`${TERMINALS_PATH}/${item.id}`}>{item.name}</Link>
					</div>
					<div className="terminals__info">
						<div className="terminals__info-title">Адрес: </div>
						<div className="terminals__address">{item?.address}</div>
					</div>
					<div className="terminals__info">
						<div className="terminals__info-title">IP-адрес: </div>
						<div className="terminals__ip">{item.currentIP}</div>
					</div>
					<div
						className={`terminals__status ${statusName[item.astipStatus].class}`}
					>
						<span>Статус: </span>
						<span>{statusName[item.astipStatus].status}</span>
					</div>
				</div>
				<div className="terminals__right">
					<Link
						to={`${TERMINALS_PATH}/${item.id}`}
						className="terminals__link btn"
					>
						<span className="terminals__link-text">Перейти к терминалу </span>
						<LoginOutlined style={{ fontSize: '15px' }} />
					</Link>
				</div>
			</div>
			{operators && <TerminalOperators operators={operators} />}

			<div className="terminals__devices-wrap">
				<div className="terminals__devices-title">Статус устройства:</div>
				<div className="terminals__devices">
					{Object.entries(deviceStatus).map(([device, status], index) => (
						<div className="terminal__device" key={index}>
							<div className="terminal__device-title-wrap">
								<span>{device.replace('Status', '')}: </span>
								<div
									className={`terminal__device-status ${
										status === 'active' ? '_active' : ''
									}`}
								>
									{status
										? status === 'active'
											? 'Онлайн'
											: 'Оффлайн'
										: 'Ошибка'}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</li>
	)
}
