import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Upload, UploadFile, UploadProps } from 'antd'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
	ChangePasswordOperator,
	LocationSelect,
	SwitchBanOperator,
	TerminalsSelect,
} from '@/components'

import {
	getAboutUser,
	loadingStatus,
	updateUser,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import {
	emailField,
	loginField,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { terminalAPI } from '@/api'

type Inputs = {
	userName: string
	email: string
	id: string
}

export function OperatorPage() {
	const currentUser = useAppSelector((state) => state.users.aboutUser)
	const [changePasModalIsOpen, setChangePasModalIsOpen] = useState(false)
	const [terminalsString, setTerminalsstring] = useState<string[]>([])
	const [trigger, setTrigger] = useState(0)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const params = useParams()
	const id = params.id

	const [fileList, setFileList] = useState<UploadFile[]>([
		{
			uid: '-1',
			name: 'image.png',
			status: 'done',
			url: 'https://basik.ru/images/jason_lee_childs/085_jason_lee_childs.jpg',
		},
	])

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
		setFileList(newFileList)

	const onSend = (file: any, fileList: any) => {
		console.log(file)
		console.log(fileList)

		return false
	}

	const closeModal = () => {
		setChangePasModalIsOpen(false)
	}

	const uploadButton = (
		<button
			className="operators__avatar-new"
			style={{ border: 0, background: 'none' }}
			type="button"
		>
			<PlusOutlined />
			<div style={{ marginLeft: '5px' }}>Загрузить новое</div>
		</button>
	)

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<Inputs>()
	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		dispatch(
			updateUser({
				UserId: data.id,
				userName: data.userName,
				email: data.email,
			})
		)

		if (terminalsString.length > 0 && id) {
			try {
				dispatch(loadingStatus(true))
				await terminalAPI.setUserTerminals({
					userId: id,
					terminalsId: terminalsString,
				})
				setTrigger((trigger) => trigger + 1)
			} catch (e) {
				console.error(e)
				openErrorNotification('Ошибка изменения терминалов пользователя')
			} finally {
				dispatch(loadingStatus(false))
			}
		}
	}

	const fetch = () => {
		if (id) {
			dispatch(getAboutUser({ UserId: id }))
		}
	}

	useEffect(() => {
		if (id) {
			dispatch(getAboutUser({ UserId: id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		reset({
			id: id,
			email: currentUser?.email,
			userName: currentUser?.userName,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser])

	return (
		<div className="operators">
			<Button className="operators__back" onClick={() => navigate(-1)}>
				Назад
			</Button>
			<div className="operators__title title">Обзор оператора</div>
			<form className="operators__wrap" onSubmit={handleSubmit(onSubmit)}>
				<div className="operators__top">
					<div className="operators__avatar">
						<Upload
							beforeUpload={(file, fileList) => onSend(file, fileList)}
							listType="picture-card"
							fileList={fileList}
							onChange={handleChange}
							maxCount={1}
							onRemove={() => false}
							showUploadList={{
								showPreviewIcon: false,
							}}
						>
							{fileList.length >= 8 ? null : uploadButton}
						</Upload>
					</div>
					<div className="operators__main">
						<div className="operators__input-wrap">
							<div className="input">
								<label className="input__label" htmlFor="userName">
									Имя
								</label>
								<Controller
									name="userName"
									control={control}
									rules={loginField}
									render={({ field }) => (
										<Input
											placeholder="Имя пользователя"
											id="userName"
											{...field}
										/>
									)}
								/>
								{errors?.userName && (
									<div className="input__error">
										{errors.userName?.message
											? errors.userName?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
						<div className="operators__input-wrap">
							<div className="input">
								<label className="input__label" htmlFor="email">
									Почта
								</label>
								<Controller
									name="email"
									control={control}
									rules={emailField}
									render={({ field }) => (
										<Input placeholder="Почта" id="email" {...field} />
									)}
								/>
								{errors?.email && (
									<div className="input__error">
										{errors.email?.message ? errors.email?.message : 'Ошибка'}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="operators__btns">
						{currentUser?.role !== 'Admin' && currentUser && (
							<SwitchBanOperator
								userId={currentUser?.id}
								ban={currentUser.isBlocked}
								fetch={fetch}
							/>
						)}

						<Button
							className="operators__btn-pass-del"
							type="primary"
							size="large"
							htmlType="button"
							onClick={() => setChangePasModalIsOpen(true)}
						>
							Сбросить пароль
						</Button>
						<Button
							className="operators__btn-del"
							danger
							type="primary"
							size="large"
							htmlType="button"
						>
							Удалить
						</Button>
					</div>
				</div>
				<div className="operators__center">
					{currentUser && (
						<LocationSelect
							currentLocation={currentUser?.location}
							userId={currentUser?.id}
						/>
					)}
					{currentUser && (
						<TerminalsSelect
							setTerminalsstring={setTerminalsstring}
							userId={currentUser.id}
							trigger={trigger}
						/>
					)}
				</div>
				<button className="operators__btn-save btn">Сохранить</button>
			</form>
			<Modal
				title="Изменение пароля"
				open={changePasModalIsOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				{currentUser && (
					<ChangePasswordOperator
						onClose={closeModal}
						name={currentUser?.userName}
						userId={currentUser.id}
					/>
				)}
			</Modal>
		</div>
	)
}
