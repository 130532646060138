import { Tabs } from 'antd'
import TerminalJurnal from 'components/Terminal/TerminalJorney/TerminalJurnal'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import {
	TerminalAppeals,
	TerminalDocs,
	TerminalMain,
	TerminalSettings,
} from '@/components'

import {
	getTerminal,
	getTerminalConfig,
	setTerminal,
	setTerminalConfig,
	useAppDispatch,
	useAppSelector,
} from '@/store'

const tabs = ['Основное', 'Настройки', 'Журнал', 'Обращения']

export function TerminalPage() {
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const [isFull, setIsFull] = useState<boolean>(false)
	const [page, setPage] = useState<string>('Основное')
	const [searchParams] = useSearchParams()

	const role = useAppSelector((state) => state.auth.role)
	const dispatch = useAppDispatch()

	const params = useParams()
	const id = params.id

	useEffect(() => {
		if (id) {
			dispatch(getTerminal(id))
			role === 'Admin' && dispatch(getTerminalConfig(id))
		}
		return () => {
			dispatch(setTerminal(null))
			dispatch(setTerminalConfig(null))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (searchParams.get('session')) {
			setPage('Основное')
		}
	}, [searchParams])

	return (
		<>
			{terminalInfo && (
				<>
					<Tabs
						defaultActiveKey="1"
						type="card"
						size="large"
						onChange={setPage}
						className="terminal__tabs"
						activeKey={page}
						items={tabs
							.filter((item) => {
								if (role !== 'Admin' && item === 'Настройки') {
									return false
								}
								return true
							})
							.map((string) => {
								return {
									label: `${string}`,
									key: `${string}`,
								}
							})}
					/>
					<div className={`terminal ${isFull ? 'terminal__full' : ''}`}>
						<div className="terminal__header">
							<h2>{terminalInfo.name}</h2>
							<p></p>
							<p>IP-адрес терминала: {terminalInfo.currentIP}</p>
							<p>Адрес терминала: {terminalInfo.address}</p>
						</div>
						{id && (
							<>
								{page === 'Основное' && (
									<TerminalMain
										id={terminalInfo.id}
										terminalId={terminalInfo.terminalId}
										setIsFull={setIsFull}
									/>
								)}
								{page === 'Настройки' && role === 'Admin' && (
									<TerminalSettings id={id} />
								)}
								{page === 'Журнал' && <TerminalJurnal id={id} />}
								{page === 'Обращения' && <TerminalAppeals id={id} />}
							</>
						)}
					</div>
				</>
			)}
		</>
	)
}
