import { Button, Input } from 'antd'
import { API_URL } from 'configs/url'
import { memo, useEffect, useState } from 'react'

import { Equipment } from '@/components'

import { useAppSelector } from '@/store'

import Ekvairing from '../../../assets/images/ekvairing.png'
import Kkm from '../../../assets/images/kkm.png'
import Printer from '../../../assets/images/printer.png'
import Skaner from '../../../assets/images/skaner.png'

import { TerminalSettingsMain } from './TerminalSettingsMain'

type DeviceStatuses = {
	printerStatus: string
	scanerStatus: string
	kkmStatus: string
	acquiringStatus: string
} | null

export const TerminalSettings = memo(({ id }: { id: string }) => {
	const [statuses, setStatuses] = useState<DeviceStatuses>(null)
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const terminalConfig = useAppSelector(
		(state) => state.terminals.currentTerminalConfig
	)

	useEffect(() => {
		const socket = new WebSocket(
			`${API_URL}/terminals/get_terminal_statuses?TerminalId=${id}`
		)

		socket.onmessage = (event) => {
			const data = JSON.parse(event.data)
			setStatuses((prev) =>
				JSON.stringify(prev) === JSON.stringify(data.Value) ? prev : data.Value
			)
		}

		return () => socket.close()
	}, [id])

	const DeviceSettings = memo(() => (
		<div className="terminalSettings__config-device">
			<div className="terminalSettings__title _h4">Настройка устройств</div>
			<div className="terminalSettings__config-device-item">
				<Equipment
					title="Принтер"
					status={statuses?.printerStatus}
					setting
					icon={Printer}
				/>
				<Equipment
					title="Сканер"
					status={statuses?.scanerStatus}
					setting
					icon={Skaner}
				/>
				<Equipment
					title="KKM"
					status={statuses?.kkmStatus}
					setting
					icon={Kkm}
				/>
				<Equipment
					title="Эквайринг"
					status={statuses?.acquiringStatus}
					setting
					icon={Ekvairing}
				/>
			</div>
		</div>
	))

	return (
		<div className="terminalSettings">
			{terminalInfo && terminalConfig && (
				<TerminalSettingsMain terminalInfo={terminalInfo} />
			)}
			<div className="terminalSettings__content">
				<div className="terminalSettings__item">
					<div className="terminalSettings__config">
						<div className="terminalSettings__title _h4">
							Конфигурация АСТИП
						</div>
						<Button className="terminalSettings__btn-down" type="primary">
							Скачать файл конфигурации
						</Button>
					</div>
					<FileUploadSection title="Загрузить конфигурацию на АСТИП" />
					<FileUploadSection title="Загрузить Рекламный ролик в раздел 1" />
					<FileUploadSection title="Загрузить Рекламный ролик в раздел 2" />
				</div>
				<div className="terminalSettings__item">
					<DeviceSettings />
				</div>
			</div>
		</div>
	)
})

const FileUploadSection = memo(({ title }: { title: string }) => (
	<div className="terminalSettings__config-download">
		<div className="terminalSettings__title _h4">{title}</div>
		<div className="terminalSettings__inner">
			<Button>Обзор</Button>
			<Input placeholder="" />
			<Button type="primary">Загрузить</Button>
		</div>
	</div>
))
