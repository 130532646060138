import { InboxOutlined } from '@ant-design/icons'
import { Button, UploadProps } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { useState } from 'react'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

import StackPrinter from './StackPrinter'

export function PrinterModal({ name, id }: { name: string; id: string }) {
	const [file, setFile] = useState<any>(null)

	const props: UploadProps = {
		name: 'file',
		multiple: true,
		maxCount: 1,
		beforeUpload(file) {
			console.log(file)
			setFile(file)
			return false
		},
		onChange(info) {
			console.log(info)
			const { status } = info.file
			if (status !== 'uploading') {
				console.log(info.file, info.fileList)
			}
			if (status === 'removed') {
				setFile(null)
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const onPrint = async () => {
		try {
			await terminalAPI.printDoc({
				terminalId: id,
				file: file,
				namePrinter: '230DNw',
			})
			openSuccessNotification('Успешная отправка документа на печать')
			setFile(null)
		} catch (e) {
			openErrorNotification('Ошибка отправки печати')
			console.error(e)
		}
	}

	return (
		<div className="terminal__modal-inner">
			<div className="terminal__modal-name">{name}</div>
			<Dragger {...props}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Выберите файл или перетащите сюда</p>
			</Dragger>
			<Button
				className={`terminal__modal-printer-btn ${file ? '_active' : ''}`}
				type="primary"
				disabled={file ? false : true}
				onClick={onPrint}
			>
				Отправить на печать
			</Button>

			<StackPrinter id={id} />
		</div>
	)
}
