import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table, TableProps } from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
	getAbonenetsList,
	getAppealsList,
	getStatusTypesList,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { appelas } from '@/utils'

import { AddAppealsModal, AppealsModal } from '../modal'

export function TerminalAppeals({ id }: { id: string }) {
	const [modalState, setModalState] = useState<{
		appealsAdd: boolean
		appeals: boolean
		appealsId: string | null
	}>({
		appealsAdd: false,
		appeals: false,
		appealsId: null,
	})

	const { statusList, typesList, listAppeals } = useAppSelector(
		(state) => state.appeals
	)

	const dispatch = useAppDispatch()

	const columns: TableProps<appelas>['columns'] = useMemo(
		() => [
			{
				title: 'Дата',
				defaultSortOrder: 'descend',
				render: (obj) => (
					<button
						onClick={() => {
							setModalState({
								...modalState,
								appealsId: obj.id,
								appeals: true,
							})
						}}
					>
						{moment(obj.dateTimeCreation).format('YYYY-MM-DD, HH:mm:ss')}
					</button>
				),
				sorter: (a, b) =>
					Date.parse(a.dateTimeCreation) - Date.parse(b.dateTimeCreation),
			},
			{
				title: 'Тип обращения',
				dataIndex: 'appealType',
				key: 'appealType',
				filters: typesList
					? typesList.map((e) => ({
							text: e.type,
							value: e.type,
						}))
					: [],
				onFilter: (value, record) =>
					record.appealType.indexOf(value as string) === 0,
			},
			{
				title: 'Статус',
				dataIndex: 'status',
				key: 'status',
				filters: statusList
					? statusList.map((e) => ({
							text: e.status,
							value: e.status,
						}))
					: [],
				onFilter: (value, record) =>
					record.status.indexOf(value as string) === 0,
			},
			{
				title: 'Терминал',
				dataIndex: 'terminal',
				render: (text) => <span>{text.name}</span>,
			},
		],
		[statusList, typesList, modalState]
	)

	const fetch = useCallback(() => {
		dispatch(getAppealsList({ AstipId: id }))
	}, [dispatch, id])

	useEffect(() => {
		dispatch(getStatusTypesList())
		dispatch(getAppealsList({ AstipId: id }))
		dispatch(getAbonenetsList({}))
	}, [dispatch, id])

	const toggleModal = (modalName: 'appealsAdd' | 'appeals', state: boolean) => {
		setModalState((prevState) => ({ ...prevState, [modalName]: state }))
	}

	return (
		<>
			<div className="terminal__appeals-wrap">
				<div className="terminal__appeals-title">Обращения</div>
				<Button
					className="terminal__appeals-btn"
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => toggleModal('appealsAdd', true)}
				>
					Добавить обращение
				</Button>
				<div className="terminal__table">
					<Table
						pagination={false}
						dataSource={listAppeals}
						columns={columns}
					/>
				</div>
			</div>
			<Modal
				open={modalState.appealsAdd}
				title="Добавление обращения"
				centered
				width={'100%'}
				style={{ maxWidth: '1000px', padding: '20px' }}
				className="terminal__modal"
				onOk={() => toggleModal('appealsAdd', false)}
				onCancel={() => toggleModal('appealsAdd', false)}
				footer={null}
			>
				<AddAppealsModal terminalId={id} fetch={fetch} />
			</Modal>
			<Modal
				open={modalState.appeals}
				title="Информация по обращению"
				centered
				width={'100%'}
				style={{ maxWidth: '1000px', padding: '20px' }}
				className="terminal__modal"
				onOk={() => toggleModal('appeals', false)}
				onCancel={() => toggleModal('appeals', false)}
				footer={null}
			>
				<AppealsModal
					terminalId={id}
					fetch={fetch}
					appealsId={modalState.appealsId}
				/>
			</Modal>
		</>
	)
}
