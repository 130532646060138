import { PdfViewer } from './PdfViewer'

export function ScannerModal({ name, id }: { name: string; id: string }) {
	return (
		<div className="terminal__modal-inner">
			<div className="terminal__modal-name">{name}</div>

			<PdfViewer id={id} />
		</div>
	)
}
