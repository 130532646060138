import { Button, Input } from 'antd'
import { useCallback, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { editTerminal, terminalItem, useAppDispatch } from '@/store'

import { requiredField } from '@/utils'

type Inputs = {
	id: string
	name: string
	address: string
	currentIP: string
}

export function TerminalSettingsMain({
	terminalInfo,
}: {
	terminalInfo: terminalItem
}) {
	const dispatch = useAppDispatch()

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<Inputs>()

	const onSubmit: SubmitHandler<Inputs> = useCallback(
		(data) => {
			console.log(data)
			dispatch(
				editTerminal({
					id: data.id,
					name: data.name,
					currentIP: data.currentIP,
					address: data.address,
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		reset({
			id: terminalInfo.id,
			currentIP: terminalInfo.currentIP,
			name: terminalInfo.name,
			address: terminalInfo.address,
		})
	}, [reset, terminalInfo])

	const InputField = ({
		name,
		label,
		placeholder,
		rules,
		errors,
	}: {
		name: keyof Inputs
		label: string
		placeholder: string
		rules: any
		errors?: any
	}) => (
		<div className="input">
			<label className="input__label" htmlFor={name}>
				{label}
			</label>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field }) => (
					<Input placeholder={placeholder} id={name} {...field} />
				)}
			/>
			{errors && (
				<div className="input__error">{errors?.message || 'Ошибка'}</div>
			)}
		</div>
	)

	return (
		<form className="terminalSettings__main" onSubmit={handleSubmit(onSubmit)}>
			<div className="terminalSettings__title _h4">Основная информация</div>
			<div className="input-inner">
				<div className="input-inner__row">
					<InputField
						name="name"
						label="Название"
						placeholder="Имя"
						rules={requiredField}
						errors={errors?.name}
					/>
					<InputField
						name="address"
						label="Адрес"
						placeholder="Адрес"
						rules={requiredField}
						errors={errors?.address}
					/>
				</div>
			</div>
			<Button
				className="terminalSettings__main-btn"
				type="primary"
				htmlType="submit"
			>
				Сохранить
			</Button>
		</form>
	)
}
