import {
	addAppeals,
	editUserType,
	getAppeals,
	getPaymentsListType,
	newUserType,
} from '../utils/types/commonType'
import { getUsersType } from '../utils/types/storeType'

import { axiosPrivate, axiosPrivateFile } from './axios'

export const authAPI = {
	login(email: string, password: string) {
		return axiosPrivate.post('auth/login', {
			email,
			password,
		})
	},

	refreshToken({
		accessToken,
		refreshToken,
	}: {
		accessToken: string
		refreshToken: string
	}) {
		return axiosPrivate.post('auth/refresh_token', {
			accessToken,
			refreshToken,
		})
	},
}

export const terminalAPI = {
	getList({ UserId }: { UserId?: string }) {
		return axiosPrivate.get(
			`terminals/get_terminals${UserId ? `?UserId=${UserId}` : ''}`
		)
	},

	getTerminalInfo({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(
			`terminals/get_terminal_info?TerminalId=${TerminalId}`
		)
	},

	getUserTerminals() {
		return axiosPrivate.get(`terminals/get_user_terminals`)
	},

	setUserTerminals({
		userId,
		terminalsId,
	}: {
		userId: string
		terminalsId: Array<string>
	}) {
		return axiosPrivate.patch(`terminals/set_user_terminals`, {
			userId,
			terminalsId,
		})
	},

	getTerminalConfig({ TerminalId }: { TerminalId: string }) {
		return axiosPrivate.get(`terminals/get_config?TerminalId=${TerminalId}`)
	},

	getStatusList() {
		return axiosPrivate.get(`terminals/status_list`)
	},

	deleteTerminal({ Id }: { Id: string }) {
		return axiosPrivate.delete(`terminals/delete_terminal?Id=${Id}`)
	},

	addNewterminal({
		name,
		currentIP,
		userId,
		astipConfigId,
	}: {
		name: string
		currentIP: string
		userId?: string
		astipConfigId?: string
	}) {
		return axiosPrivate.post('terminals/add_terminal', {
			name,
			currentIP,
			...(userId && { userId }),
			...(astipConfigId && { astipConfigId }),
		})
	},

	addNewStatus({ id, status }: { id: string; status: number }) {
		return axiosPrivate.patch(`terminals/set_status_terminal`, {
			id,
			status,
		})
	},
	editTerminal({
		id,
		name,
		currentIP,
		astipConfigId,
		address,
	}: {
		id: string
		name: string
		currentIP: string
		astipConfigId?: string
		address?: string
	}) {
		return axiosPrivate.patch(`terminals/edit_terminal`, {
			id,
			name,
			currentIP,
			...(astipConfigId && { astipConfigId }),
			...(address && { address }),
		})
	},
	printDoc({
		terminalId,
		namePrinter,
		file,
	}: {
		terminalId: string
		namePrinter: string
		file: string
	}) {
		const myData = new FormData()
		myData.append('file', file)
		myData.append('terminalId', terminalId)
		myData.append('namePrinter', namePrinter)
		return axiosPrivateFile.post(`terminals/print_doc`, myData)
	},
	scanDoc({ terminalId }: { terminalId: string }) {
		return axiosPrivate.get(
			`terminals/read_scanned_doc?terminalId=${terminalId}`,
			{
				responseType: 'blob',
			}
		)
	},
	saveScanDoc({
		terminalId,
		appealId,
		name,
	}: {
		terminalId: string
		appealId: string | null
		name: string
	}) {
		return axiosPrivate.post(`terminals/save_scanned_docs`, {
			terminalId,
			appealId,
			nameFile: name,
		})
	},
	removeScanDoc({ terminalId }: { terminalId: string }) {
		return axiosPrivate.patch(`terminals/remove_scanned_docs`, {
			terminalId,
		})
	},
	moveCamera({
		terminalId,
		direction,
		duration = 1,
	}: {
		terminalId: string
		direction: number
		duration?: number
	}) {
		return axiosPrivate.post(`terminals/move_camera`, {
			terminalId,
			direction,
			duration,
		})
	},
	getDocuments({
		TerminalId,
		AppealId,
	}: {
		TerminalId: string
		AppealId?: string
	}) {
		return axiosPrivate.get(`terminals/get_documnets`, {
			params: {
				TerminalId,
				AppealId,
			},
		})
	},
	getDocument({ DocumentId }: { DocumentId: string }) {
		return axiosPrivate.get(
			`terminals/get_url_document?DocumentId=${DocumentId}`,
			{
				responseType: 'blob',
			}
		)
	},
	deleteQuery({
		TerminalId,
		IdElement,
	}: {
		TerminalId: string
		IdElement: number
	}) {
		return axiosPrivate.delete(
			`terminals/delete_query_element?TerminalId=${TerminalId}&IdElement=${IdElement}`
		)
	},
}
export const journalsAPI = {
	getJournals({
		id,
		DateTimeStart,
		DateTimeEnd,
		EventType,
		Importance,
	}: {
		id: string
		DateTimeStart?: string
		DateTimeEnd?: string
		EventType?: number
		Importance?: number
	}) {
		let paramStroke = `id=${id}`
		if (DateTimeStart) {
			paramStroke += `&DateTimeStart=${DateTimeStart}`
		}
		if (DateTimeEnd) {
			paramStroke += `&DateTimeEnd=${DateTimeEnd}`
		}
		if (EventType) {
			paramStroke += `&EventType=${EventType}`
		}
		if (Importance) {
			paramStroke += `&Importance=${Importance}`
		}
		return axiosPrivate.get(`journals/get_astip_journal?${paramStroke}`)
	},
	getImportance() {
		return axiosPrivate.get(`appeals/importance_list`)
	},
	getEventType() {
		return axiosPrivate.get(`appeals/event_type_list`)
	},
}

export const terminalCallAPI = {
	accept_call() {
		return axiosPrivate.patch(`terminal_call/accept_call`)
	},
	delete_call({ UserId }: { UserId: string }) {
		return axiosPrivate.patch(
			`terminal_call/delete_call`,
			{},
			{
				params: {
					UserId,
				},
			}
		)
	},
	reject_call() {
		return axiosPrivate.patch(`terminal_call/reject_call`)
	},
	end_call() {
		return axiosPrivate.patch(`terminal_call/end_call`)
	},
	receive_operator_call(terminalId: string) {
		return axiosPrivate.post(`terminal_call/receive_operator_call`, {
			terminalId,
		})
	},
	check_user_is_in_call() {
		return axiosPrivate.get(`terminal_call/check_user_is_in_call`)
	},
}

export const usersAPI = {
	getUsers({ UserName, Email, IsActive, Role, IsBlocked }: getUsersType) {
		let stroke = ''
		const object = {
			...(UserName && { UserName }),
			...(Email && { Email }),
			...(IsActive && { IsActive }),
			...(Role && { Role }),
			...(IsBlocked && { IsBlocked }),
		}
		for (const key in object) {
			//@ts-ignore
			console.log(object[key])
			//@ts-ignore
			stroke += `${key}=${object[key]}&`
		}

		return axiosPrivate.get(`users/get_users${stroke ? `?${stroke}` : ''}`)
	},
	getUser({ UserId }: { UserId: string }) {
		return axiosPrivate.get(`users/get_user_info?UserId=${UserId}`)
	},
	addUser({ userName, email, password }: newUserType) {
		return axiosPrivate.post(`users/add`, {
			userName,
			email,
			password,
		})
	},
	switchStatus(IsActive: boolean) {
		return axiosPrivate.patch(
			`users/switch_status`,
			{},
			{
				params: {
					IsActive,
				},
			}
		)
	},
	banUser({ UserId }: { UserId: string }) {
		return axiosPrivate.patch(`users/ban?UserId=${UserId}`)
	},
	unBanUser({ UserId }: { UserId: string }) {
		return axiosPrivate.patch(`users/unban?UserId=${UserId}`)
	},
	updateUser({ userName, email, id }: editUserType) {
		return axiosPrivate.post(`users/edit`, {
			userName,
			email,
			id,
		})
	},
	newPassUser({ UserId, password }: { UserId: string; password: string }) {
		return axiosPrivate.patch(`users/set_new_password`, {
			password,
			userId: UserId,
		})
	},
	getUsersLocations() {
		return axiosPrivate.get(`users/get_users_locations`)
	},
	setUsersLocations({
		userId,
		locationId,
	}: {
		userId: string
		locationId: number
	}) {
		return axiosPrivate.patch(`users/set_user_location`, { userId, locationId })
	},
}

export const appealsAPI = {
	getList({
		Type,
		Status,
		CreatedStart,
		AbonentId,
		AstipId,
		UserId,
		CreatedEnd,
	}: getAppeals) {
		let stroke = ''
		const object = {
			...(Type && { Type }),
			...(Status && { Status }),
			...(CreatedStart && { CreatedStart }),
			...(CreatedEnd && { CreatedEnd }),
			...(AbonentId && { AbonentId }),
			...(AstipId && { AstipId }),
			...(UserId && { UserId }),
		}
		for (const key in object) {
			//@ts-ignore
			stroke += `${key}=${object[key]}&`
		}

		return axiosPrivate.get(`appeals/list${stroke ? `?${stroke}` : ''}`)
	},
	getStatutList() {
		return axiosPrivate.get(`appeals/status_list`)
	},
	getTypesList() {
		return axiosPrivate.get(`appeals/types_list`)
	},
	addAppeals({ type, status, comment, abonentId, terminalId }: addAppeals) {
		return axiosPrivate.post(`appeals/add`, {
			type,
			status,
			comment,
			abonentId,
			terminalId,
		})
	},
	getAppeals(AppealId: string) {
		return axiosPrivate.get(`appeals/get_info?AppealId=${AppealId}`)
	},
	getPaymentsList({
		TerminalName,
		TerminalId,
		StartedAt,
		EndedAt,
		PersonalAcc,
		Status,
		PaymentType,
	}: getPaymentsListType) {
		let stroke = ''
		const object = {
			...(TerminalName && { TerminalName }),
			...(TerminalId && { TerminalId }),
			...(StartedAt && { StartedAt }),
			...(EndedAt && { EndedAt }),
			...(PersonalAcc && { PersonalAcc }),
			...(Status && { Status }),
			...(PaymentType && { PaymentType }),
		}
		for (const key in object) {
			//@ts-ignore
			stroke += `${key}=${object[key]}&`
		}
		return axiosPrivate.get(
			`appeals/get_payments_list${stroke ? `?${stroke}` : ''}`
		)
	},
	addDocument({
		appealId,
		documentId,
	}: {
		appealId: string
		documentId: string
	}) {
		return axiosPrivate.post(`appeals/add_document`, {
			appealId,
			documentId,
		})
	},
	setComment({
		appealId,
		comment,
	}: {
		appealId: string
		comment: string | null
	}) {
		return axiosPrivate.patch(`appeals/set_comment`, {
			appealId,
			comment,
		})
	},
	setStatus({
		appealId,
		appealStatus,
	}: {
		appealId: string
		appealStatus: number
	}) {
		return axiosPrivate.patch(`appeals/set_status`, {
			appealId,
			appealStatus,
		})
	},
	editAfterCall({
		appealId,
		abonentPersonalAcc,
	}: {
		appealId: string
		abonentPersonalAcc?: string | null
	}) {
		return axiosPrivate.patch(`appeals/edit_after_call`, {
			appealId,
			abonentPersonalAcc,
		})
	},
}

export const abonentAPI = {
	addAbonent({
		personalAcc,
		email,
		phoneNumber,
	}: {
		personalAcc: string
		email: string
		phoneNumber: string
	}) {
		return axiosPrivate.post(`abonents/add`, {
			personalAcc,
			email,
			phoneNumber,
		})
	},
	getAbonent({ Filter }: { Filter?: string }) {
		let stroke = ''
		const object = {
			...(Filter && { Filter }),
		}
		for (const key in object) {
			//@ts-ignore
			console.log(object[key])
			//@ts-ignore
			stroke += `${key}=${object[key]}&`
		}
		return axiosPrivate.get(`abonents/get_list${stroke ? `?${stroke}` : ''}`)
	},
}
