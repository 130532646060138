import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Button, Input } from 'antd'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import {
	loadingStatus,
	removeScanDoc,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import {
	loginField,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { terminalAPI } from '@/api'

interface PdfViewerProps {
	id: string
}

type Inputs = {
	name: string
}

export const PdfViewer: FC<PdfViewerProps> = ({ id }) => {
	const { appealId } = useAppSelector((state) => state.appeals)
	const [pdfFile, setPdfFile] = useState<Array<string>>([])
	const [previewPdfFile, setPreviewPdfFile] = useState<string>('')
	const [isFirstFile, setIsFirstFile] = useState<boolean>(true)
	const dispatch = useAppDispatch()

	const fetchPdf = async () => {
		try {
			dispatch(loadingStatus(true))

			const response = await terminalAPI.scanDoc({ terminalId: id })
			const blob = new Blob([response.data])
			console.log(response)
			setPdfFile([...pdfFile, URL.createObjectURL(blob)])
			setPreviewPdfFile(URL.createObjectURL(blob))
			setIsFirstFile(false)
		} catch (error) {
			console.error('Ошибка при сканировании:', error)
			openErrorNotification('Ошибка при сканировании ')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<Inputs>()

	const onSaveDocs: SubmitHandler<Inputs> = async (data) => {
		try {
			dispatch(loadingStatus(true))
			await terminalAPI.saveScanDoc({
				terminalId: id,
				appealId: appealId!,
				name: data.name,
			})
			openSuccessNotification('Сохранение документов успешное')
			setPdfFile([])
			setPreviewPdfFile('')
			setIsFirstFile(true)
			reset()
		} catch (error) {
			console.error('Ошибка при сохранение:', error)
			openErrorNotification('Ошибка при сохранение ')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	useEffect(() => {
		return () => {
			if (isFirstFile) {
				dispatch(removeScanDoc({ terminalId: id }))
			}
			setPreviewPdfFile('')
			setPdfFile([])
			setIsFirstFile(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<form onSubmit={handleSubmit(onSaveDocs)}>
			<Button
				className={'terminal__modal-scanner-btn'}
				size="large"
				type="primary"
				htmlType="button"
				onClick={fetchPdf}
			>
				{isFirstFile ? 'Сканировать' : 'Сканировать еще'}
			</Button>
			{pdfFile.length ? (
				<>
					<div className="terminal__modal-scanner-preview">
						<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
							<Viewer
								fileUrl={previewPdfFile}
								defaultScale={SpecialZoomLevel.PageFit}
							/>
						</Worker>
					</div>
					<div className="terminal__modal-scanner-wrap">
						{pdfFile.map((e, i) => (
							<button
								type="button"
								key={i}
								onClick={() => setPreviewPdfFile(e)}
								className="terminal__modal-wrap-pdf-btn"
							>
								<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
									<Viewer fileUrl={e} defaultScale={SpecialZoomLevel.PageFit} />
								</Worker>
							</button>
						))}
					</div>
				</>
			) : isFirstFile ? (
				''
			) : (
				<p>Загрузка документа...</p>
			)}
			{!isFirstFile && (
				<div className="input terminal__modal-input">
					<label className="input__label" htmlFor="name">
						Имя файла
					</label>
					<Controller
						name="name"
						control={control}
						rules={loginField}
						render={({ field }) => (
							<Input placeholder="Имя" id="name" {...field} />
						)}
					/>
					{errors?.name && (
						<div className="input__error">
							{errors.name?.message ? errors.name?.message : 'Ошибка'}
						</div>
					)}
				</div>
			)}

			<Button
				className={'terminal__modal-scanner-btn'}
				size="large"
				//onClick={onSaveDocs}
				disabled={isFirstFile}
				htmlType="submit"
			>
				Сохранить
			</Button>
		</form>
	)
}
