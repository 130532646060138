import { Button, Select } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { getStatusList, useAppDispatch, useAppSelector } from '@/store'

import {
	openErrorNotification,
	openSuccessNotification,
	requiredField,
} from '@/utils'

import { terminalAPI } from '@/api'

import { currentTerminalType } from '../TerminalList'

type addNewStatus = {
	status: number
}

export const AddNewStatus = ({
	onClose,
	fetch,
	currentTerminal,
}: {
	onClose: () => void
	fetch: () => void
	currentTerminal: currentTerminalType | null
}) => {
	const statusList = useAppSelector((state) => state.terminals.listStatus)
	const dispatch = useAppDispatch()

	const translateStatusToRus = useCallback((status: string) => {
		switch (status) {
			case 'None':
				return 'Отсутствует'
			case 'Disabled':
				return 'Отключен'
			case 'Offline':
				return 'Не в сети'
			case 'Online':
				return 'В сети'
			default:
				return 'Не определен'
		}
	}, [])

	const transformStatusToId = useCallback((status: string) => {
		switch (status) {
			case 'Disabled':
				return 3
			case 'Offline':
				return 2
			case 'Online':
				return 1
			default:
				return 4
		}
	}, [])

	const dataForSelect = useMemo(() => {
		const statusOptions =
			statusList?.map((e: any) => ({
				value: e.id,
				label: translateStatusToRus(e.status),
			})) || []

		statusOptions.push({ value: 4, label: 'Не определен' })
		return statusOptions
	}, [statusList, translateStatusToRus])

	const {
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<addNewStatus>({
		defaultValues: {
			status: currentTerminal
				? transformStatusToId(currentTerminal?.status)
				: 4,
		},
	})

	const onSubmit: SubmitHandler<addNewStatus> = async (data) => {
		try {
			if (currentTerminal) {
				await terminalAPI.addNewStatus({
					id: currentTerminal?.id,
					status: data.status,
				})
			}
			await fetch()
			onClose()
			openSuccessNotification('Статус изменен')
		} catch (e: any) {
			openErrorNotification('Ошибка изменения статуса')
		}
	}

	useEffect(() => {
		dispatch(getStatusList())
		return () => {
			reset()
		}
	}, [dispatch, reset])

	useEffect(() => {
		reset({
			status: currentTerminal
				? transformStatusToId(currentTerminal?.status)
				: 4,
		})
	}, [currentTerminal, reset, transformStatusToId])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="inputs-wrap">
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="status">
							Статус
						</label>
						<Controller
							name="status"
							control={control}
							rules={requiredField}
							render={({ field }) => (
								<Select
									{...field}
									style={{ width: 120 }}
									options={dataForSelect}
								/>
							)}
						/>
						{errors?.status && (
							<div className="input__error">
								{errors.status?.message ? errors.status?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="terminals__form-add-bottom">
				<Button htmlType="button" onClick={onClose}>
					Отмена
				</Button>
				<Button htmlType="submit" type="primary">
					Добавить
				</Button>
			</div>
		</form>
	)
}
