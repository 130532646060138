import { Button, Input } from 'antd'
import { SubmitHandler, useController, useForm } from 'react-hook-form'

import {
	ipField,
	openErrorNotification,
	openSuccessNotification,
	requiredField,
} from '@/utils'

import { terminalAPI } from '@/api'

type addNewTerminal = {
	name: string
	currentIP: string
}

export const AddNewTerminal = ({
	onClose,
	fetch,
}: {
	onClose: () => void
	fetch: () => void
}) => {
	const {
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<addNewTerminal>()

	const onSubmit: SubmitHandler<addNewTerminal> = async (data) => {
		try {
			await terminalAPI.addNewterminal({
				name: data.name,
				currentIP: data.currentIP,
			})
			reset()
			await fetch()
			onClose()
			openSuccessNotification('Успешное добавление терминала')
		} catch (e: any) {
			openErrorNotification('Ошибка при добавление терминала')
		}
	}

	const { field: nameField, fieldState: nameFieldState } = useController({
		name: 'name',
		control,
		rules: requiredField,
	})

	const { field: ipFieldController, fieldState: ipFieldState } = useController({
		name: 'currentIP',
		control,
		rules: ipField,
	})

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="inputs-wrap">
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="name">
							Имя
						</label>
						<Input placeholder="Имя" id="name" {...nameField} />
						{nameFieldState?.error && (
							<div className="input__error">
								{nameFieldState.error?.message || 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="currentIP">
							ip-адрес
						</label>
						<Input placeholder="ip" id="currentIP" {...ipFieldController} />
						{ipFieldState?.error && (
							<div className="input__error">
								{ipFieldState.error?.message || 'Ошибка'}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="terminals__form-add-bottom">
				<Button htmlType="button" onClick={onClose}>
					Отмена
				</Button>
				<Button htmlType="submit" type="primary">
					Добавить
				</Button>
			</div>
		</form>
	)
}
