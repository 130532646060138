import { Button, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import {
	editAppeal,
	getAppeal,
	setCurrentAppeals,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import {
	addAppeals,
	commentField,
	openErrorNotification,
	requiredField,
} from '@/utils'

import { TerminalDocsAppeal } from '../docs/TerminalDocsAppeal'
import { TerminalDocsAppealNew } from '../docs/TerminalDocsAppealNew'

type AppealsModalProps = {
	terminalId: string
	fetch: () => void
	appealsId: string | null
}

export function AppealsModal({
	terminalId,
	fetch,
	appealsId,
}: AppealsModalProps) {
	const statusList = useAppSelector((state) => state.appeals.statusList)
	const appeal = useAppSelector((state) => state.appeals.currentAppeals)

	const dispatch = useAppDispatch()

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<addAppeals>()

	const onSubmit: SubmitHandler<addAppeals> = (data) => {
		if (appealsId) {
			dispatch(
				editAppeal({
					AppealId: appealsId,
					comment: data.comment || null,
					appealStatus: data.status,
				})
			)
			fetch()
		} else {
			openErrorNotification('Ошибка')
		}
	}

	const dataForSelectStatus = useMemo(() => {
		if (!statusList) return []
		return statusList.map((e) => ({
			value: e.id,
			label: e.status,
		}))
	}, [statusList])

	useEffect(() => {
		if (appealsId) {
			dispatch(getAppeal({ AppealId: appealsId }))
		}

		return () => {
			dispatch(setCurrentAppeals(null))
		}
	}, [appealsId, dispatch])

	useEffect(() => {
		if (appeal && statusList?.length) {
			const status = statusList.find((e) => e.status === appeal.status)?.id
			reset({
				terminalId,
				status,
				comment: appeal.comment,
			})
		}
	}, [appeal, statusList, reset, terminalId])

	return (
		<div className="terminal__modal-inner terminal__appeals">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="terminal__appeals-date">
					Дата и время:{' '}
					<span>
						{moment(appeal?.dateTimeCreation).format('YYYY-MM-DD, HH:mm:ss')}
					</span>
				</div>
				<div className="terminal__appeals-top">
					<div className="terminal__appeals-left">
						<div className="terminal__appeals-big-title">
							Имя терминала: {appeal?.terminal.name}
						</div>
						<div className="input-inner">
							<div className="input-inner__row">
								<AppealInfo appeal={appeal} />
							</div>
							<div className="input-inner__row">
								<div className="terminal__appeals-item">
									<div className="terminal__appeals-item-title">Статус</div>
									<Controller
										name="status"
										control={control}
										rules={requiredField}
										render={({ field }) => (
											<Select
												{...field}
												style={{ width: 220 }}
												placeholder="Выберите статус"
												options={dataForSelectStatus}
											/>
										)}
									/>
									{errors?.status && (
										<ErrorMessage message={errors.status?.message} />
									)}
								</div>
							</div>
							<div className="input-inner__row">
								<div className="terminal__appeals-item">
									<div className="input">
										<div className="terminal__appeals-item-title">
											Комментарий
										</div>
										<Controller
											name="comment"
											control={control}
											render={({ field }) => (
												<TextArea
													{...field}
													placeholder="Комментарий"
													autoSize={{ minRows: 2, maxRows: 6 }}
												/>
											)}
										/>
										{errors?.comment && (
											<ErrorMessage message={errors.comment?.message} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="terminal__appeals-right">
						<div className="terminal__appeals-big-title">
							Оператор: {appeal?.user?.userName}
						</div>
						{appeal?.documents && (
							<TerminalDocsAppealNew data={appeal?.documents} />
						)}
					</div>
				</div>

				<div className="terminal__appeals-call">
					<div className="terminal__appeals-big-title">Информация о звонке</div>
					<div className="terminal__appeals-item">
						Статус звонка: {appeal?.callRecordView?.status} <br />
						Продолжительность:{' '}
						{appeal?.callRecordView?.callDuration
							? `${Math.round(appeal.callRecordView.callDuration * 100) / 100}(мин)`
							: 'Нет информации'}{' '}
						<br />
						Инициатор звонка:{' '}
						{appeal?.callRecordView
							? appeal?.callRecordView.direction === 0
								? 'терминал'
								: 'оператор'
							: ''}
					</div>
				</div>
				<div className="terminal__appeals-bottom">
					<Button
						className="terminal__appeals-bottom-btn"
						type="primary"
						htmlType="submit"
						size="large"
					>
						Сохранить изменения
					</Button>
				</div>
			</form>
		</div>
	)
}

const AppealInfo = ({ appeal }: { appeal: any }) => (
	<div className="terminal__appeals-item">
		<div className="terminal__appeals-item-title">Абонент</div>
		<div className="terminal__appeals-item">
			Лицевой счет: {appeal?.abonent?.personalAcc} <br />
			Тип обращения: {appeal?.appealType}
		</div>
	</div>
)

const ErrorMessage = ({ message }: { message: string | undefined }) => (
	<div className="input__error">{message || 'Ошибка'}</div>
)
