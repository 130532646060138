import Payments from 'components/Payments/Payments'

export function ReportsPage() {
	return (
		<div className="reports">
			<div className="reports__title title">Отчеты</div>
			<div className="reports__inner">
				<Payments />
			</div>
		</div>
	)
}
