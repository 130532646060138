import { terminalCallAPI, usersAPI } from 'api/api'
import { useEffect } from 'react'
import {
	Navigate,
	Outlet,
	useNavigate,
	useSearchParams,
} from 'react-router-dom'
import router from 'routes'

import { Loading } from '@/components'

import {
	setAppealId,
	setCallRecordId,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { MAIN_PATH } from '@/utils'

const AppLayout = () => {
	const { loading, init } = useAppSelector((state) => state.app)
	const { isAuth } = useAppSelector((state) => state.auth)
	const dispatch = useAppDispatch()
	const router = useNavigate()
	const [searchParams] = useSearchParams()

	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			console.log('Пользователь покидает сайт')
			usersAPI.switchStatus(false).catch((error) => {
				console.error('Ошибка при обновлении статуса при выходе:', error)
			})

			event.returnValue = ''
		}
		const checkCall = async () => {
			const { data } = await terminalCallAPI.check_user_is_in_call()
			console.log(data)
			if (data.isInCall) {
				console.log(data)
				dispatch(setAppealId(data.appealId))
				dispatch(setCallRecordId(data.callRecordId))
				dispatch(setTypeCall('operator'))
				router(`/terminals/${data.terminalId}?session=${data.appealId}`, {
					replace: true,
				})
			}
		}

		if (!searchParams.get('session')) {
			checkCall()
		}

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [])

	if (!init) {
		return <Loading />
	}

	if (!isAuth) {
		return <Navigate to={MAIN_PATH} />
	}

	return (
		<>
			{loading ? <Loading /> : ''}
			<Outlet />
		</>
	)
}

export default AppLayout
