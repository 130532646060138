import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useEffect, useState } from 'react'

import { AddNewTerminal, TerminalList } from '@/components'

import { getListTerminals, useAppDispatch, useAppSelector } from '@/store'

export function TerminalsPage() {
	const listTerminals = useAppSelector((state) => state.terminals.terminalsList)
	const role = useAppSelector((state) => state.auth.role)
	const dispatch = useAppDispatch()

	const [addNewTerminalIsOpen, setAddNewTerminalIsOpen] = useState(false)

	const closeModal = () => {
		setAddNewTerminalIsOpen(false)
	}

	const fetch = async () => {
		dispatch(getListTerminals({}))
	}

	useEffect(() => {
		dispatch(getListTerminals({}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="terminals">
			<div className="terminals__title title">Терминалы</div>

			{role === 'Admin' && (
				<Button
					className="terminals__add"
					icon={<PlusOutlined />}
					onClick={() => setAddNewTerminalIsOpen(true)}
				>
					Добавить терминал
				</Button>
			)}

			<TerminalList listTerminals={listTerminals} fetch={fetch} />

			<Modal
				title="Добавление нового терминала"
				open={addNewTerminalIsOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				<AddNewTerminal fetch={fetch} onClose={closeModal} />
			</Modal>
		</div>
	)
}
