import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { terminalAPI } from 'api/api'
import { API_URL } from 'configs/url'
import React, { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import { openErrorNotification } from '@/utils'

type queryItem = {
	id: number
	fileName: string
	isPrinting: boolean
}

export default function StackPrinter({ id }: { id: string }) {
	const [query, setQuery] = useState<Array<queryItem> | null>(null)
	const dispatch = useAppDispatch()

	const deleteDoc = async (idDoc: number) => {
		try {
			dispatch(loadingStatus(true))
			const response = await terminalAPI.deleteQuery({
				TerminalId: id,
				IdElement: idDoc,
			})
		} catch (error) {
			console.error('Ошибка при удаление очереди:', error)
			openErrorNotification('Ошибка при удаление очереди ')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	useEffect(() => {
		const socket = new WebSocket(
			`${API_URL}/terminals/get_query?TerminalId=${id}&namePrinter=230DNw`
		)
		socket.addEventListener('message', (event) => {
			setQuery(JSON.parse(event.data))
		})
		return () => socket.close()
	}, [])

	return (
		<>
			{query && query.length > 0 && (
				<>
					<div className="terminal__modal-printer-stack">Очередь печати</div>
					<ul className="terminal__modal-printer-list">
						{query.map((e) => (
							<li className="terminal__modal-printer-item" key={e.id}>
								<div className="terminal__modal-printer-item-name">
									{e.fileName}
								</div>
								<Button
									className="terminal__modal-printer-item-del"
									type="primary"
									danger
									icon={<DeleteOutlined />}
									onClick={() => deleteDoc(e.id)}
								></Button>
							</li>
						))}
					</ul>
				</>
			)}
		</>
	)
}
