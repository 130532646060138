import { Button } from 'antd'
import { ReactNode } from 'react'

interface Props {
	title: string
	status: any
	icon?: string
	setting?: boolean
}

export const Equipment = ({ title, status, setting, icon }: Props) => {
	return (
		<div className="device" style={{ minWidth: 150 }}>
			<p className="device__title">
				{title} <img src={icon} alt="terminal__device-btn" />
			</p>
			<p
				className={`device__status device__status--success`}
				// className={`device__status ${status ? 'device__status--success' : 'device__status--error'}`}
			>
				{/* {status ? status : 'Ошибка'} */}
				Готов
			</p>
			{setting && <Button>Настроить</Button>}
		</div>
	)
}
