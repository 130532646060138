import { Select } from 'antd'
import { useEffect, useState } from 'react'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

type terminalType = {
	user: string
	id: string
	name: string
	currentIP: string
	astipStatus: string
}

export function TerminalsSelect({
	userId,
	trigger,
	setTerminalsstring,
}: {
	userId: string
	trigger: number
	setTerminalsstring: any
}) {
	const [listTerminals, setListTerminals] = useState<null | Array<{
		value: string
		label: string
	}>>(null)

	const [userTerminals, setUserTerminals] = useState<null | Array<{
		value: string
		label: string
	}>>(null)

	const defaultValue = userTerminals?.map((e) => e.value)

	const dataForSelect = (array: Array<terminalType>) => {
		return array?.map((e: any) => ({
			value: e.id,
			label: `${e.name}, ${e.currentIP}`,
		}))
	}

	const handleChange = async (value: string[]) => {
		setTerminalsstring(value)
	}

	const fetch = async () => {
		try {
			const userTerminals = await terminalAPI.getList({ UserId: userId })
			const allTerminals = await terminalAPI.getList({})
			setListTerminals(dataForSelect(allTerminals.data))
			setUserTerminals(dataForSelect(userTerminals.data))
		} catch (e: any) {
			openErrorNotification('Ошибка загрузки терминалов')
		}
	}

	useEffect(() => {
		fetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId])
	useEffect(() => {
		fetch()
	}, [trigger])

	return (
		<div className="operators__input-wrap">
			<label
				className="operators__label"
				style={{ display: 'block' }}
				htmlFor="terminals"
			>
				Привязанные терминалы
			</label>
			{listTerminals && userTerminals && (
				<Select
					key={defaultValue ? defaultValue[0] : null}
					mode="multiple"
					defaultValue={defaultValue}
					style={{ width: '100%' }}
					placeholder="Задайте терминалы пользователю"
					onChange={handleChange}
					options={listTerminals}
				/>
			)}
		</div>
	)
}
