import { loadingStatus, setEventType, setImportanceType, setJournal } from '..'

import { openErrorNotification } from '@/utils'

import { journalsAPI } from '@/api'

export const getJournals =
	({
		id,
		DateTimeStart,
		DateTimeEnd,
		EventType,
		Importance,
	}: {
		id: string
		DateTimeStart?: string
		DateTimeEnd?: string
		EventType?: number
		Importance?: number
	}) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const { data } = await journalsAPI.getJournals({
				id,
				DateTimeStart,
				DateTimeEnd,
				EventType,
				Importance,
			})
			if (data) {
				const array = data.map((e: any) => {
					return {
						...e,
						key: e.id,
					}
				})
				dispatch(setJournal(array))
			}
		} catch (e: any) {
			openErrorNotification('Ошибка получения информации о журнале событий')
			console.error(e)
		}
		dispatch(loadingStatus(false))
	}

export const getJournalsTypes = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const importance = await journalsAPI.getImportance()
		const event = await journalsAPI.getImportance()

		dispatch(setImportanceType(importance.data))
		dispatch(setEventType(event.data))
	} catch (e: any) {
		openErrorNotification('Ошибка получения типов')
		console.error(e)
	}
	dispatch(loadingStatus(false))
}
