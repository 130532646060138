import { DatePicker, Table, TableColumnsType, TableProps } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'

import {
	getJournals,
	getJournalsTypes,
	useAppDispatch,
	useAppSelector,
} from '@/store'

const { RangePicker } = DatePicker

type OnChange = NonNullable<TableProps<DataType>['onChange']>
type Filters = Parameters<OnChange>[1]

interface DataType {
	key: React.Key
	id: string
	dateTimeCreation: string
	importance: string
	eventType: string
	message: string
}

export default function TerminalJurnal({ id }: { id: string }) {
	const [filteredInfo, setFilteredInfo] = useState<Filters>({})
	const [dateFilter, setDateFilter] = useState<any>(null)
	const { journalList, eventType, importanceType } = useAppSelector(
		(state) => state.journals
	)

	const dispatch = useAppDispatch()

	const onLoadList = () => {
		dispatch(getJournals({ id }))
		dispatch(getJournalsTypes())
	}

	const handleChange: OnChange = (pagination, filters) => {
		setFilteredInfo(filters)
		let objectParams: any = {
			id,
		}
		if (dateFilter) {
			objectParams.DateTimeStart = dateFilter.DateTimeStart
			objectParams.DateTimeEnd = dateFilter.DateTimeEnd
		}
		if (filters) {
			if (filters.eventType) {
				objectParams.EventType = filters.eventType[0]
			}
			if (filters.importance) {
				objectParams.Importance = filters.importance[0]
			}
		}
		dispatch(getJournals(objectParams))
	}

	const columns: TableColumnsType<DataType> = [
		{
			title: 'Дата',
			dataIndex: 'dateTimeCreation',
			render: (obj) => {
				console.log(obj)
				return <div>{moment(obj).format('YYYY-MM-DD, HH:mm:ss')}</div>
			},
		},
		{
			title: 'Важность',
			dataIndex: 'importance',
			filters: importanceType?.map((e: any) => ({
				text: e.type,
				value: e.type,
				data: e.id,
			})),
		},
		{
			title: 'Тип события',
			dataIndex: 'eventType',
			filters: eventType?.map((e: any) => ({
				text: e.type,
				value: e.type,
				data: e.id,
			})),
		},
		{
			title: 'Описание ',
			dataIndex: 'message',
		},
	]

	const onDateChange = (date: any, dateString: any) => {
		setDateFilter({
			DateTimeStart: dateString[0],
			DateTimeEnd: dateString[1],
		})
		let objectParams: any = {
			id,
			DateTimeStart: dateString[0],
			DateTimeEnd: dateString[1],
		}
		if (filteredInfo) {
			if (filteredInfo.eventType) {
				objectParams.EventType = filteredInfo.eventType[0]
			}
			if (filteredInfo.importance) {
				objectParams.Importance = filteredInfo.importance[0]
			}
		}
		dispatch(getJournals(objectParams))
	}

	useEffect(() => {
		onLoadList()
	}, [])

	return (
		<div className="jornal">
			<div className="jornal__title title">Журнал событий</div>

			<div className="jornal__filter">
				<div className="jornal__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>

			<Table
				columns={columns}
				dataSource={journalList}
				onChange={handleChange}
			/>
		</div>
	)
}
