import { operatorItem } from 'store/terminals'

const TerminalOperators = ({
	operators,
}: {
	operators: Array<operatorItem>
}) => {
	return (
		<div className="terminals__operators">
			<div className="terminals__operators-name">Закреплённые операторы:</div>
			<ul className="terminals__operators-list">
				{operators.map((e: operatorItem) => (
					<li className="terminals__operators-item" key={e.id}>
						{e.userName}
					</li>
				))}
				{operators.length === 0 && (
					<li className="terminals__operators-item _accent">
						Нет привязанных операторов
					</li>
				)}
			</ul>
		</div>
	)
}

export default TerminalOperators
